body {
	font-family: "Ubuntu";
}

.app {
	z-index: 1;
	display: flex;
	flex-wrap: wrap;
	height: 100vh;

	.app-half {
		flex: 1;
		text-align: center;
		min-height: 100vh;
		max-height: 100vh;
		padding-top: 80px;

		@media only screen and (min-width: 768px) {
			overflow: scroll;
		}

	}


	.app-half-right {
		padding-top: 0px;
	}

}

.link {
	color: #bd29a9;
	text-decoration: underline;
	cursor: pointer;
}

a {
	color: #bd29a9;
	text-decoration: underline;
}

.profile-image-large {
	width: 343px;
	height: 319px;

	border: 3px solid #2CD4BF;
	padding: 3px;

	border-radius: 228px;
	margin-bottom: 40px;
}

.content-container {
	text-align: left;
	margin-left: 70px;
	margin-right: 70px;

	@media only screen and (max-width: 768px) {
		margin-left: 20px;
		margin-right: 20px;
	}
}

.cta {
	padding: 20px 70px 20px 70px;
	color: white;
	background-color: #111827;
	margin-right: 0px;
	margin-left: 0px;
}

.nav {
	padding-top: 30px;
	padding-bottom: 30px;
	background-color: #f0f2f7;
	border-bottom: #111827 solid 2px;

	ul {
		list-style-type: none;
		margin: 0;
		padding: 0;
	}
	  
	li {
		margin-left: 10px;
		margin-right: 10px;
		padding-bottom: 3px;
		display: inline;

		&.main-nav-active {
			border-bottom: 2px solid #111827;
		}

		&:hover {
			border-bottom: 2px solid #111827;
			cursor: pointer;
        }
	}
}

.border-none {
	border: none;
}

.chatGPTPrompt {
	background-color: #e3e6ed;
	padding: 10px 10px 10px 10px;
	border-radius: 8px;
	margin-top: 10px;
	margin-bottom: 10px;
	display: flex;

	p {
		padding: 0;
		margin: 0;
		padding-left: 16px;
	}

	.chatgpt-thread-icon {
		width: 24px;
		height: 24px;
	}
}

textarea {
	border-radius: 8px;
	padding: 6px 10px 6px 10px;
	box-sizing: border-box;
	width: 100%;
	resize: none;
	margin-bottom: 10px;
	margin-top: 10px;
	font: inherit;
}

input[type=text] {
	box-sizing: border-box;
	padding: 6px 10px 6px 10px;
	margin-bottom: 10px;
	margin-top: 10px;
	width: 100%;
}

.chatGPTResponse {
	background-color: #f0f2f7;
	padding: 10px 8px 10px 8px;
	border-radius: 8px;
	margin-top: 10px;
	margin-bottom: 10px;
	display: flex;

	.chatGPTContent {
		padding: 0;
		margin: 0;
		padding-left: 16px;
		white-space: initial;
		word-break: break-word;
		overflow: scroll;

		p {
			margin: 0;
			margin-bottom: 20px;
		}
	}

	.chatgpt-thread-icon {
		width: 24px;
		height: 24px;
	}

	pre {
		padding: 8px 10px 8px 10px;
		border-radius: 8px;
		background-color: #6f7582;
		color: white;
		line-height: 1.7;
		overflow: auto;

		code {
			padding: 0;
			background-color: #6f7582;
			color: white;
			border-radius: none;
			white-space: break-word;
			overflow-wrap: break-word;
			word-wrap: break-word;
		}
	}

	code {
		color: #111827;
		font-weight: 700;
		white-space: break-word;
		word-wrap: break-word;
		overflow-wrap: break-word;
	}
}

.markdown {
	padding: 4px;
	white-space: initial;
	word-break: break-word;
	overflow: scroll;
	font: inherit;
	

	pre {
		padding: 8px 10px 8px 10px;
		border-radius: 8px;
		background-color: #6f7582;
		color: white;
		white-space: pre-wrap;       /* Since CSS 2.1 */
		white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
		white-space: -pre-wrap;      /* Opera 4-6 */
		white-space: -o-pre-wrap;    /* Opera 7 */
		word-wrap: break-word;       /* Internet Explorer 5.5+ */
	}

	code {
		padding: 0;
		background-color: #6f7582;
		color: white;
		border-radius: none;
	}

	p {
		margin: 0;
		margin-bottom: 20px;
	}
}