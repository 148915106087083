.has-secondary-color {
	color: #2CD4BF;
}

.has-third-color {
	color: #111827;
}


.has-white-text {
	color: white;
}

.has-primary-bg {
	background-color: #111827
}

.mt-60 {
	margin-top: 60px;
}

.mb-60 {
	margin-bottom: 60px;
}

.justify-content-left {
	justify-content: left;
}

.mb-40 {
	margin-bottom: 40px;
}

.mb-10 {
	margin-bottom: 10px;
} 

.mt-40 {
	margin-top: 40px;
}

.mr-40 {
	margin-right: 40px;
} 

.ml-40 {
	margin-left: 40px;
}

.hidden {
	display: none !important;
}

.display-block {
	display: block;
}

img {
	width: 100%;
}

.color-danger {
	color: rgb(181, 41, 41);
}

.min-h-page {
	min-height: 40vh;	
}

.h-200 {
	min-height: 200px;	
}

.text-center {
	text-align: center;
}

code {
	background: #ECEFF1;
	color: #78909C;
	border-radius: 2px;  
}